
  import { defineComponent, ref } from 'vue'
  import usePayoutRound from "@/hooks/checking/spotChecking/pendingApproval/usePayoutRound";
  import { ColumnProps } from 'ant-design-vue/es/table/interface';
  type Key = ColumnProps['key'];
  const tableWidth = window.innerWidth
  const tableHeight = window.innerHeight-431
  import TableWrapper from "@/components/TableWrapper.vue";
  import { useStore } from "vuex";
  import BuSelector from "@/views/Payment/components/BuSelector.vue";
  import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
  export default defineComponent({
    name: 'payoutPreparation',
    components: {
      // Pagination,
      TableWrapper,
      BuSelector,
      EntitySelector
    },
    props: {},
    setup() {
      const store = useStore()
      const {
        searchParams, showParams, doReset,
        columnsData, tableData, doSearch, pageChange, sizeChange, pageSize, pageNumber, total,
        programNameClick
      } = usePayoutRound()
      const handlePageChange = (page: number,pageSize: number) =>{
        pageChange(page, pageSize)
      }
      const handleSizeChange = (page: number,pageSize: number) =>{
        sizeChange(page, pageSize)
      }
      
      return {
        store,
        searchParams,
        showParams,
        doSearch,
        doReset,
        tableWidth,
        tableHeight,
        columnsData,
        tableData,
        pageSize,
        pageNumber,
        total,
        handlePageChange,
        handleSizeChange,
        programNameClick
      }
    }
  })
