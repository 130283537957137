import { ref, reactive, computed, onMounted } from "vue";
import * as API from "@/API/checking/spotChecking";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import useThousands from "@/utils/payment/useThousands";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import useFetch from "@/hooks/useFetch";
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { getSubscribedBu } from "@/API/approve";
import { message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

type SearchParams = {
    entity?: string[];
    bu?: string[];
    programCode: string;
    programName: string;
}

type ShowParams = {
    payoutRoundId: string;
    payoutRound: string;
    payoutRoundStatus: string;
    wTotal: string;
    woTotal: string;
}

export interface TableData {
    programId: string;
    entity: string;
    bu: string;
    programCode: string;
    programName: string;
    dealerNo: string;
    vinNo: string;
    amountWVat: string;
    amountWOVat: string;
}

const useVehicleNameSet = () => {
    const { state, commit } = useStore()
    const router = useRouter()

    const showParams = reactive<ShowParams>({
        payoutRoundId: '',
        payoutRound: '',
        payoutRoundStatus: '',
        wTotal: '',
        woTotal: ''
    })
    const searchParams = reactive<SearchParams>({
        entity: undefined,
        bu: undefined,
        programCode: '',
        programName: '',
    })
    const total = ref<number>(0)
    const pageNumber = ref<number>(0)
    const pageSize = ref<number>(10)
    const columnsData = computed(() => {
        return [
            { title: "Entity", dataIndex: "entity",width:180},
            { title: "BU", dataIndex: "bu"},
            { title: "Program Code", dataIndex: "programCode",width:250},
            { title: "Program Name", dataIndex: "programName",slots: { customRender: 'programName' },width: 140,},
            { title: "Dealer No.", dataIndex: "dealerNo", customRender: useThousands(), align: 'right' },
            { title: "VIN No.", dataIndex: "vinNo", customRender: useThousands(), align: 'right' },
            { title: "Amount w VAT", dataIndex: "amountWVat", customRender: useThousandsAndTwoDecimal(), width: 230, align: "right"},
            { title: "Amount w/o VAT", dataIndex: "amountWOVat", customRender: useThousandsAndTwoDecimal(), width: 230, align: "right"},
            // { title: "Operation", dataIndex: "operation",slots: { customRender: 'operation' }}
        ]
    })
    const tableData = ref<TableData[]>()
    const getTableData = () => {
        const params = {
            bus: searchParams.bu,
            entities: searchParams.entity,
            payoutRoundId: showParams.payoutRoundId,
            programCode: searchParams.programCode,
            programName: searchParams.programName
        }
        API.getProgramList(params).then((data: any) => {
            tableData.value = data.spotCheckProgramDTOS
            // total.value = data.totalElements
            // showParams.payoutRoundStatus = data.payoutRoundStatus
            showParams.wTotal = toThousandsAndTwoDecimal(data.totalAmountWVat)
            showParams.woTotal = toThousandsAndTwoDecimal(data.totalAmountWOVat)
        })
    }
    const getShowParams = () => {
        const curRow = state.spotChecking.payoutRoundData;
        showParams.payoutRoundId = curRow.payoutRoundId
        showParams.payoutRound = curRow.payoutRound
        showParams.payoutRoundStatus = curRow.status
    }
    const doSearch = () => {
        console.log('search按钮')
        pageNumber.value = 1
        getTableData()
    }
    const doReset = () => {
        Object.assign(searchParams, {
            entity: undefined,
            bu: undefined,
            programCode: '',
            programName: '',
        })
        tableData.value = []
        getTableData()
    }
    const pageChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    };
    const sizeChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    }
    const programNameClick = (record: any) => {
        const info = {
            payoutRound: showParams.payoutRound,
            programName: record.programName,
            programIds: record.programIds,
            payoutRoundStatus: showParams.payoutRoundStatus,
            amountWVat: record.amountWVat,
            amountWOVat: record.amountWOVat
        }
        commit('spotChecking/updatePayoutCurRow', info)
        router.push({ path: '/spotChecking/pendingApproval/payoutDetail' })
    }
    onMounted(() => {
        getShowParams()
        doSearch()
    })
    return {
        searchParams,
        showParams,
        columnsData,
        tableData,
        doSearch,
        doReset,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        programNameClick
    }
}
export default useVehicleNameSet